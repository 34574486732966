<template>
  <OtherBanner :type="11" />

  <section class="filter-box" id="pageTop">
    <div class="content" v-if="ISHTSY">
      <!-- 讲师等级 -->
      <ListFilter
        only
        :name="$t('lecturer.level')"
        :dataSource="passStatus"
        @onChange="passStatusChange"
        class="filter-lang"
      />
    </div>
    <div class="content" v-else>
      <!-- 讲师分类 -->
      <TreeFilter
        :name="$t('lecturer.classify')"
        :dataSource="folderData"
        :treeData2="folderTreeData"
        :treePath2="folderTreePath"
        @onChange="folderChange"
        class="filter-lang"
      />
      <!-- 讲师等级 -->
      <ListFilter
        only
        :name="$t('lecturer.level')"
        :dataSource="passStatus"
        @onChange="passStatusChange"
        class="filter-lang"
      />
    </div>
  </section>

  <section class="toolbar">
    <div class="content">
      <div class="crumbs">
        {{ $t("current_position") }}：
        <!-- 当前位置： -->
        <span
          v-for="(item, index) in folderCrumbs"
          :key="item.id"
          @click="crumbsClick(item, index)"
        >
          {{ item.name }}
          <i>></i>
        </span>
      </div>
      <div class="search">
        <a-input-search
          v-model:value.trim="searchVal"
          :placeholder="$t('please_enter_keywords')"
          enter-button
          allow-clear
          @search="search"
        />
        <!-- 请输入关键字 -->
      </div>
    </div>
  </section>

  <section class="lecturer">
    <div class="content">
      <a-spin :spinning="lecturerLoading">
        <template v-if="lecturerData.length">
          <div class="cards clearfix">
            <div
              class="item"
              v-for="item in lecturerData"
              :key="item.lecturerId"
            >
              <router-link
                :to="{
                  path: '/lecturer/detail',
                  query: { id: item.lecturerId },
                }"
              >
                <div class="portrait">
                  <img
                    :src="item.portrait"
                    alt="portrait"
                    v-if="item.portrait"
                  />
                  <img
                    src="@/assets/image/teacher_avatar.png"
                    alt="portrait"
                    v-else
                  />
                  <div class="intro" v-if="item.motto">
                    <div class="text">
                      <span>{{ $t("lecturer.lecturer_profile") }}：</span
                      >{{ item.motto || "-" }}
                    </div>
                  </div>
                </div>
                <div class="info">
                  <div class="user-name">
                    <OpenData
                      type="userName"
                      :openid="item.lecturerName"
                      v-if="item.lecturerType == 1"
                    />
                    <template v-else>
                      {{ item.lecturerName }}
                    </template>
                  </div>
                  <div class="depart">
                    <OpenData
                      type="departmentName"
                      :openid="item.departmentName"
                    />
                  </div>
                  <div class="level">
                    <div class="icon">
                      <img :src="item.levelPicture" alt="level" />
                    </div>
                    <div class="name">
                      <a-popover trigger="hover" placement="topLeft">
                        <template #content>
                          <div class="popover-content">
                            {{ item.levelName }}
                          </div>
                        </template>
                        {{ item.levelName }}
                      </a-popover>
                    </div>
                  </div>
                  <div class="flex bottom">
                    <div class="label">
                      {{ $t("LB_Favorite_Score") }}：<span>{{
                        item.score
                      }}</span>
                    </div>
                    <div class="label">
                      {{ $t("total_class_hours") }}：<span>{{
                        item.courseTimes
                      }}</span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :defaultPageSize="20"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import TreeFilter from "@/components/new/filter/TreeFilter.vue";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import OtherBanner from "@/components/banner/OtherBanner.vue";
import { lecturerList, lecturerFolder, lecturerLevel } from "@/api/lecturer";
import { getCdnUrl } from "@/utils/tools";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    TreeFilter,
    ListFilter,
    renderVNode,
    OtherBanner,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    // 目录
    let folderId = Number(route.query.folderId || 0);
    let folderCrumbs = ref([
      {
        id: 0,
        name: $t("CM_All"),
        // 全部
      },
    ]);

    // 讲师目录
    let folderData = ref([]);

    const getNewObj = (data) => {
      return JSON.parse(JSON.stringify(data));
    };

    let tempFolderCrumbs = [];
    let tempFolderData = [];
    let tempFolderPath = [];
    const traverseTree = (data) => {
      let str = `"id":${folderId},`;
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let strItem = JSON.stringify(item);
        if (strItem.includes(str)) {
          tempFolderCrumbs.push(item);
          tempFolderData.push([
            {
              id: tempFolderData.length == 0 ? 0 : item.id,
              name: $t("CM_All"),
            },
            ...data,
          ]);
          tempFolderPath.push(i);
          if (item.id == folderId && item.list && item.list.length) {
            tempFolderData.push([
              {
                id: tempFolderData.length == 0 ? 0 : item.id,
                name: $t("CM_All"),
              },
              ...item.list,
            ]);
            tempFolderPath.push(-1);
          }
          if (item.id != folderId && item.list && item.list.length) {
            traverseTree(item.list);
          }
          break;
        }
      }
    };

    lecturerFolder().then((res) => {
      if (res.ret === 0) {
        folderData.value = res.data || [];
        if (folderId) {
          traverseTree(getNewObj(folderData.value));
          folderCrumbs.value = tempFolderCrumbs;
          folderCrumbs.value = getNewObj(tempFolderCrumbs);
          folderTreeData.value = getNewObj(tempFolderData);
          folderTreePath.value = getNewObj(tempFolderPath);
        }
      }
    });

    let folderTreeData = ref([]);
    let folderTreePath = ref([]);
    const folderChange = ({ activeId, crumbs, reload, treeData, treePath }) => {
      folderId = activeId;
      folderCrumbs.value = crumbs;
      folderTreeData.value = treeData;
      folderTreePath.value = treePath;
      getLecturerList();
    };

    // 讲师等级
    let passStatus = ref([]);
    let estate = [];
    let passStatusActive = 0;
    lecturerLevel().then((res) => {
      if (res.ret === 0) {
        let d = res.data || [];
        d.forEach((item) => {
          item.name = item.levelName;
          item.id = item.levelId;
          delete item.levelName;
          delete item.levelId;
          if (ISHTSY) {
            if (item.name !== "临时讲师") estate.push(item);
          } else {
            estate.push(item);
          }
        });
        if (estate.length && ISHTSY) {
          //passStatusActive = estate[0].id;
          getLecturerList();
        }
        passStatus.value = estate || [];
      }
    });

    const passStatusChange = (res) => {
      console.log(res[0]);
      passStatusActive = res[0];
      getLecturerList();
    };

    // 面包屑
    const crumbsClick = (data, index) => {
      let dom = document.getElementById(`tree_${index}_${data.id}`);
      dom.click();
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getLecturerList();
      };

    // 教师列表
    const lecturerData = ref([]),
      lecturerLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getLecturerList = (scroll = false) => {
        lecturerLoading.value = true;
        lecturerList({
          folderId: folderId, // 讲师分类
          keyword: searchVal.value, // 关键字查询
          levelID: passStatusActive, // 讲师等级
          page: currentPage.value, // 当前页(导出时可不填)
          pageSize: 20, // 每页记录数量(导出时可不填)
          sort: 0, // 排序 0不处理 1正序排序 2倒序排序
          sortKey: "", // 按KEY排序
        }).then((res) => {
          lecturerLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            lecturerData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getLecturerList(true);
      };

    // 讲师详情
    let router = useRouter();
    const details = (id) => {
      router.push({
        path: "/lecturer/detail",
        query: {
          id: id,
        },
      });
    };
    if (!ISHTSY) getLecturerList();

    return {
      ISHTSY,
      folderData,
      folderTreeData,
      folderTreePath,
      folderCrumbs,
      folderChange,
      crumbsClick,
      passStatus,
      passStatusChange,
      searchVal,
      search,
      getLecturerList,
      lecturerData,
      lecturerLoading,
      currentPage,
      pageTotal,
      pageChange,
      details,
      getCdnUrl,
    };
  },
};
</script>

<style lang="less" scoped>
.filter-box {
  background-color: @color-page-gray;
  padding-bottom: 14px;
  .content {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
    .mixinWrap();
    // ::v-deep(.filter-lang) {
    //   .label {
    //     width: 78px !important;
    //   }
    //   .values {
    //     width: calc(100% - 88px) !important;
    //   }
    // }
  }
}

.toolbar {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    .mixinFlex(space-between; center);
    padding: 20px 0 16px;
    .crumbs {
      width: calc(100% - 250px);
      font-size: 14px;
      color: #999;
      span {
        cursor: pointer;
        i {
          padding: 0 2px;
        }
        &:hover {
          color: @color-theme;
        }
        &:last-child {
          .noevents();
          i {
            display: none;
          }
          &:hover {
            color: #999;
          }
        }
      }
    }
  }
}

.lecturer {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      .mixinFlex();
      flex-wrap: wrap;
      .item {
        width: 224px;
        margin: 0 20px 20px 0;
        position: relative;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(148, 148, 148, 0.1);
        overflow: hidden;
        transition: all 0.3s ease;
        &:nth-child(5n) {
          margin-right: 0;
        }
        .portrait {
          width: 100%;
          height: 299px;
          border-radius: 8px 8px 0 0;
          position: relative;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
          .intro {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0;
            background-color: rgba(0, 0, 0, 0.5);
            transition: height 0.3s;
            .text {
              margin: 12px;
              .mixinEllipsis(104px, 4);
              color: #fff;
              font-size: 14px;
              span {
                font-weight: bold;
              }
            }
          }
        }
        .info {
          padding: 12px;
          color: #666;
          .flex {
            .mixinFlex(space-between, center);
          }
          .user-name {
            font-size: 14px;
            font-weight: 600;
            color: #333;
            .mixinEllipsis(26px);
          }
          .depart {
            .mixinEllipsis(26px);
            margin-top: 6px;
            color: #666;
            font-size: 14px;
          }
          .level {
            .mixinFlex(flex-start, center);
            margin-top: 6px;
            .icon {
              width: 20px;
              height: 20px;
              img {
                width: 100%;
                height: 100%;
                vertical-align: top;
              }
            }
            .name {
              width: calc(100% - 28px);
              .mixinEllipsis(26px);
              color: #666;
            }
          }
          .bottom {
            margin-top: 6px;
            .label {
              color: #666;
              font-size: 14px;
              span {
                color: #f90;
                font-weight: 600;
              }
            }
          }
        }
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.25);
          .portrait {
            .intro {
              height: 128px;
            }
          }
        }
      }
    }
  }
}
</style>
